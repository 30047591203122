import { FC, ReactNode } from 'react';
import { Paper, PaperProps } from '@mui/material';

import { styles } from './styles';

interface InnerSectionPaperProps extends PaperProps {
  children: ReactNode;
}

export const InnerSectionPaper: FC<InnerSectionPaperProps> = ({
  children,
  ...rest
}) => {
  return (
    <Paper sx={styles.container} {...rest}>
      {children}
    </Paper>
  );
};
