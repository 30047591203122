import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Input, MessageBoxType } from 'react-chat-elements';
import { Grid, Popover, Typography } from '@mui/material';
import { UNASSIGNED_TASKS } from 'constants/common';
import { format } from 'date-fns';
import { useChatMessages } from 'hooks/useChatMessages';
import { useHasChanged } from 'hooks/useHasChanged';
import { useSelectedOrder } from 'hooks/useSelectedOrder';
import {
  useChatByResourceIdQuery,
  useQuickResponsesQuery,
  useSendChatMessageMutation,
  useSetChatMessageStatusMutation,
  useUnreadMessagesByResourceQuery,
  useUpdateQuickReplyByMessageIdMutation,
} from 'store/api/chatApiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { updateQuickReplyByMessageId } from 'store/reducers/chatSlice';
import {
  getIsCurrentResourceHasWorkshift,
  getSelectedResourceIdSelector,
} from 'store/selectors/resource';
import {
  getCommonDateSelector,
  getIsDedicatedChatScreen,
  getOnlyCurrentWorkshiftSelector,
} from 'store/selectors/settings';
import {
  getCurrentWorkshifIdtSelector,
  getIsCurrentOrFutureWorkshiftSelector,
} from 'store/selectors/workshift';
import { t } from 'ttag';
import { SendingMessage, ShipmentStatuses } from 'types/api';
import { ChatMessage } from 'types/chat';

import { QuickReplies } from 'components/ui/QuickReplies';

import { MessageBox } from './components/MessageBox';
import { SendButton } from './components/SendButton';
import { styles } from './styles';

import 'react-chat-elements/dist/main.css';

// eslint-disable-next-line @typescript-eslint/no-empty-function
let clearRef = () => {};

export const Chat: FC = () => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(
    null,
  );
  const [clickedMessage, setClickedMessage] = useState<ChatMessage | null>(
    null,
  );
  const [isEmptyInput, setIsEmptyInput] = useState(true);
  const [isDataSending, setDataSending] = useState(false);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [scrollHeight, setScrollHeight] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const isDedicatedChatScreen = useAppSelector(getIsDedicatedChatScreen);
  const isCurrentResourceHasWorkshift = useAppSelector(
    getIsCurrentResourceHasWorkshift(selectedResourceId),
  );

  const selectedWorkshiftId = useAppSelector(getCurrentWorkshifIdtSelector);
  const { selectedOrder } = useSelectedOrder();
  const isCurrentOrFutureWorkshift = useAppSelector(
    getIsCurrentOrFutureWorkshiftSelector,
  );
  const dispatch = useAppDispatch();
  const [sendChatMessage] = useSendChatMessageMutation();
  const [setChatMessageStatus] = useSetChatMessageStatusMutation();
  const { refetch } = useChatByResourceIdQuery(
    {
      resourceId: selectedResourceId as string,
      workshiftId: selectedWorkshiftId as string,
    },
    {
      skip: !selectedResourceId || selectedResourceId === UNASSIGNED_TASKS,
    },
  );
  const onlyCurrentWorkshift = useAppSelector(getOnlyCurrentWorkshiftSelector);
  const commonDate = useAppSelector(getCommonDateSelector);

  const preparedCommonDate = useMemo(() => {
    return commonDate ? commonDate : format(new Date(), 'yyyy-MM-dd');
  }, [commonDate]);

  const { refetch: unreadMessagesRefetch } = useUnreadMessagesByResourceQuery({
    date: onlyCurrentWorkshift ? undefined : preparedCommonDate,
  });
  const { data: quickReplies } = useQuickResponsesQuery();
  const [updateQuickReplyBiMessageId] =
    useUpdateQuickReplyByMessageIdMutation();

  const { chatMessages, managerMessagesIds } = useChatMessages(selectedOrder);

  const hasMessagesIdsChanged = useHasChanged(chatMessages);

  const setAndGetUnreadStatuses = async (
    managerIds: string[],
    resourceId: string,
  ) => {
    await setChatMessageStatus({
      messagesIds: managerIds,
      resource_id: resourceId,
    });

    await unreadMessagesRefetch();
    await refetch();
  };

  useEffect(() => {
    if (managerMessagesIds.length && selectedResourceId) {
      setAndGetUnreadStatuses(managerMessagesIds, selectedResourceId);
    }
  }, []);

  useEffect(() => {
    if (
      messageContainerRef.current &&
      (hasMessagesIdsChanged || scrollHeight)
    ) {
      setScrollHeight(messageContainerRef.current?.clientHeight || 0);
      messageContainerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [hasMessagesIdsChanged, scrollHeight]);

  useEffect(() => {
    if (
      managerMessagesIds.length &&
      hasMessagesIdsChanged &&
      selectedResourceId
    ) {
      setAndGetUnreadStatuses(managerMessagesIds, selectedResourceId);
    }
  }, [managerMessagesIds, selectedResourceId, hasMessagesIdsChanged]);

  useEffect(() => {
    refetch?.();
  }, [selectedResourceId, selectedWorkshiftId]);

  const handleSendPress = useCallback(async () => {
    setDataSending(true);

    if (!inputRef?.current?.value?.trim()) {
      setDataSending(false);
      return;
    }

    const shipmentId =
      selectedOrder?.id.startsWith('group-') ||
      selectedOrder?.id.startsWith('terminal-')
        ? null
        : selectedOrder?.shipmentId
        ? selectedOrder.shipmentId
        : null;

    const request: SendingMessage = {
      type: 'text',
      content: inputRef?.current?.value,
      work_shift_id: selectedWorkshiftId as string,
    };

    if (shipmentId) {
      request.shipment_id = shipmentId;
    }

    try {
      await sendChatMessage(request);

      if (inputRef?.current?.value) {
        inputRef.current.value = '';
      }
      await clearRef();

      await refetch?.();
    } finally {
      setDataSending(false);
    }
  }, [selectedWorkshiftId, selectedOrder]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target?.value?.trim()) {
        setIsEmptyInput(true);
      } else {
        setIsEmptyInput(false);
      }
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setClickedMessage(null);
  }, []);

  const handleMessageClick = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      setAnchorEl(e.currentTarget);
    },
    [],
  );

  const onClickReply = useCallback(
    (replies: number[]) => {
      dispatch(
        updateQuickReplyByMessageId({
          messageId: clickedMessage?.id as string,
          quickReplies: replies,
        }),
      );
      updateQuickReplyBiMessageId({
        messageId: clickedMessage?.id as string,
        quickReplies: replies,
      });
      handleClose();
    },
    [clickedMessage],
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const isDisabledSendButton = useMemo(() => {
    return isEmptyInput || isDataSending;
  }, [isDataSending, isEmptyInput]);

  if (
    !selectedResourceId ||
    selectedResourceId === UNASSIGNED_TASKS ||
    selectedOrder?.shipmentStatus === ShipmentStatuses.ASSIGNED
  ) {
    return (
      <Grid sx={styles.emptyContainer}>
        <Typography component="span" variant="body1" sx={styles.linkTitle}>
          {t`Select resource (except unassigned) and order(except assigned) to see chat`}
        </Typography>
      </Grid>
    );
  }

  if (!isCurrentResourceHasWorkshift && !isDedicatedChatScreen) {
    return (
      <Grid sx={styles.emptyContainer}>
        <Typography component="span" variant="body1" sx={styles.linkTitle}>
          {t`Select resource doesn't have current workshift`}
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <QuickReplies
          allReplies={quickReplies || []}
          activeReplies={clickedMessage?.quickReplies || []}
          onClickReply={onClickReply}
        />
      </Popover>
      <Grid
        sx={{
          ...styles.messageListContainer,
          height: !isCurrentOrFutureWorkshift ? '100%' : 'calc(100% - 80px)',
        }}
      >
        <div ref={messageContainerRef}>
          {chatMessages &&
            chatMessages.map((message, idx) => (
              <MessageBox
                key={`Message Item ${idx}`}
                onContextMenu={
                  message.position === 'left'
                    ? (e) => {
                        handleMessageClick(e);
                        setClickedMessage(message as ChatMessage);
                      }
                    : undefined
                }
                {...(message as MessageBoxType)}
              />
            ))}
        </div>
      </Grid>
      {isCurrentOrFutureWorkshift && (
        <Grid sx={styles.chatInputContainer}>
          <Input
            className="rce-example-input"
            placeholder={t`Message`}
            defaultValue=""
            referance={inputRef}
            clear={(clear: any) => (clearRef = clear)}
            maxHeight={50}
            onChange={handleInputChange}
            onKeyPress={async (e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.shiftKey && e.charCode === 13) {
                return true;
              }

              // if (inputRef?.current && inputRef?.current?.value?.length > MAX_MESSAGE_LENGTH) {
              //   e.preventDefault();
              //   return false;
              // }

              if (e.charCode === 13) {
                await handleSendPress();
              }
            }}
            rightButtons={
              <SendButton
                onClick={handleSendPress}
                disabled={isDisabledSendButton}
              />
            }
          />
        </Grid>
      )}
    </>
  );
};
