import {
  Address,
  BookingData,
  BookingPackage,
  ErrandData,
  ErrandDataProofTypes,
  ErrandProofTypes,
  ShipmentStatuses,
} from './api';

export const enum OrderStatuses {
  DECLINED = 'Declined',
  DONE = 'Done',
  FAILED = 'Failed',
  PENDING = 'Pending',
}
export const enum OrderAddressTypes {
  COURIER = 'Courier',
  TERMINAL = 'Terminal',
}
export const enum OrderTypes {
  PICK = 'Pick',
  DROP = 'Drop',
}

export interface ProofDataInnerData {
  is_damaged?: boolean;
  order_confirmation_comment?: string;
  order_confirmation_photo_urls?: string[];
  order_execution_comment?: string;
  order_execution_photo_urls?: string[];
  order_cancellation_photo_urls?: string[];
  order_cancellation_comment?: string;
  signature_url?: string;
  surname?: string;
  firstName?: string;
  digits?: number[];
  proxy_person_digits?: number[];
  lat: number;
  lng: number;
  nobody_available_to_sign?: boolean | null;
  name?: string;
  reasons?: ErrandProofTypes[];
  client_personal_id?: string;
  proxy_personal_id?: string;
  num_of_files?: number;
}
export interface ProofData {
  data?: ProofDataInnerData;
  date?: string;
  driver_id: string;
  id: string;
  proof_type: ErrandProofTypes;
}
export interface CommonOrderData {
  id: string;
  shipmentId: string;
  bookingId: string;
  status: OrderStatuses;
  type: OrderTypes;
  isHidden: boolean;
  isNew: boolean;
  isEditable: boolean;
  isCollapsible: boolean;
  orderNumber: number | null;
  bookingNumber: string | null;
  freightBill?: string;
  address: OrderAddress;
  pickAddress?: OrderAddress;
  subtitle: string | undefined | null;
  time?: OrderTime;
  data?: OrderData;
  additionsData: OrderAdditionsData | null;
  deliveryData: OrderDeliveryData | null;
  packages: OrderPackage[] | null;
  shipmentStatus: ShipmentStatuses;
  proofDate?: number;
  proofData: ProofData | null;
  bookingData: BookingData | null;
  isTerminalReturn: boolean;
  isManuallyCreated: boolean;
  pickTerminalId?: string | null;
  dropTerminalId?: string | null;
}

export interface Order extends CommonOrderData {
  dataTitle?: string | null;
  deliveryInstruction?: string;
  hint?: string;
  contraryOrder?: Order;
  dropOptions?: Order[];
  isCurrent?: boolean;
}

export type OrderDeliveryData = Pick<
  BookingData,
  'goods_type' | 'general_parcel_type' | 'goods_marking' | 'pallet_location'
>;

export type OrderAdditionsData = Omit<
  BookingData,
  'sender' | 'receiver' | 'number'
> &
  Pick<
    ErrandData,
    | 'on_call_courier'
    | 'time_slotted_lorry'
    | 'unloading_time'
    | 'ferry_addition_cost'
    | 'loading_time'
  > &
  Partial<{
    new_pickup_address?: boolean | null;
    new_delivery_address?: boolean | null;
    pickup_waiting_time?: number | null;
    delivery_waiting_time?: number | null;
  }>;

export type OrderPackage = Omit<
  BookingPackage,
  'created_by_driver' | 'booking_id'
>;

export interface OrderAddress extends Address {
  type?: OrderAddressTypes;
  title?: string;
  subtitle?: string;
}

export interface OrderTime {
  difference?: number;
  pod?: string;
  eta?: string | null;
  earliest?: Date;
  latest?: Date;
  be_terminal_latest?: Date | null;
  leave_terminal_latest?: Date | null;
  fullEta?: string | null;
}

export interface OrderData {
  instruction?: string | null;
  phoneNumber?: string | null;
  recipientName?: string | null;
  enter_code?: string | null;
  intercom?: string | null;
  elevator?: boolean | null;
  knock_on_the_door?: boolean | null;
  floor?: string | null;
  packaging_return?: boolean | null;
  proofType?: ErrandDataProofTypes | null;
  ageControl?: number | null;
  isManualCreated?: boolean | null;
  quiet_pickup?: boolean | null;
  quiet_delivery?: boolean | null;
  use_doorbell: boolean | null;
}

export interface AllRoutesSelectedOrder {
  orderId: string;
  terminalId: string | null;
  resourceId: string;
  workshiftId: string;
}
