import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid, ListItem, Typography } from '@mui/material';
import temperedGoodsImg from 'assets/images/tempered_goods.png';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setSelectedOrderId } from 'store/reducers/orderSlice';
import { calculateUnreadMessagesForShipment } from 'store/selectors/chat';
import { getAllRoutesSelectedOrder } from 'store/selectors/order';
import { Order } from 'types/orders';

import { InnerSectionPaper } from 'components/common/InnerSectionPaper';
import { QueryParamsLink } from 'components/common/QueryParamsLink';
import { UnreadLabel } from 'components/common/UnreadLabel';
import { EditButton } from 'components/ui/EditButton';

import { styles } from './styles';

interface DetailsSectionInnerOrdersItemProps {
  order: Order;
  isTerminalReturn?: boolean;
}

export const DetailsSectionInnerOrdersItem: FC<
  DetailsSectionInnerOrdersItemProps
> = ({ order, isTerminalReturn = false }) => {
  const dispatch = useDispatch();
  const unreadMessages = useAppSelector(
    calculateUnreadMessagesForShipment(order?.shipmentId),
  );
  const allRoutesSelectedOrder = useAppSelector(getAllRoutesSelectedOrder);

  const isTempered = order?.bookingData?.tempered_transport;

  const clickHandler = useCallback(() => {
    if (allRoutesSelectedOrder) {
      return;
    }
    dispatch(setSelectedOrderId(order.id));
  }, [allRoutesSelectedOrder, dispatch, order]);

  return (
    <ListItem sx={styles.item} onClick={clickHandler}>
      <InnerSectionPaper>
        <Grid container columns={11} sx={styles.content}>
          <Grid item mobile={8} alignSelf="center">
            <Typography variant="h3">
              {order?.[isTerminalReturn ? 'pickAddress' : 'address']?.[
                'title'
              ] || ''}
            </Typography>
            <Typography sx={styles.addressSubtitle}>
              {order?.[isTerminalReturn ? 'pickAddress' : 'address']?.[
                'subtitle'
              ] || ''}
            </Typography>
            {!isTerminalReturn && <Typography>{order.subtitle}</Typography>}
          </Grid>

          <Grid item mobile={1} alignSelf="center" pl={1}>
            {isTempered && <img src={temperedGoodsImg} alt="Tempered goods" />}
          </Grid>

          {!allRoutesSelectedOrder && (
            <>
              <Grid
                item
                mobile={2}
                alignSelf="center"
                textAlign="center"
                pl={1}
              >
                {!!unreadMessages && (
                  <QueryParamsLink sx={styles.unread}>
                    <UnreadLabel qty={unreadMessages} />
                  </QueryParamsLink>
                )}
              </Grid>
              {order.isEditable && (
                <Box sx={styles.editButton}>
                  <EditButton orderId={order.id} />
                </Box>
              )}
            </>
          )}

          {/* <Grid item mobile={2} alignSelf="center" textAlign="right">*/}
          {/*  {order.isEditable && <EditButton />}*/}
          {/* </Grid>*/}
        </Grid>
      </InnerSectionPaper>
    </ListItem>
  );
};
