import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, ListItem, SxProps, Typography } from '@mui/material';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { updateOnlyWithUnread } from 'store/reducers/resourceSlice';
import {
  getUnreadMessagesByResourceIdV2,
  isAnyUnreadMessageSelector,
} from 'store/selectors/chat';
import { getOnlyWithUnreadSelector } from 'store/selectors/resource';
import { getOnlyCurrentWorkshiftSelector } from 'store/selectors/settings';
import { getCurrentWorkshifIdtSelector } from 'store/selectors/workshift';
import { Resource } from 'types/resource';

import { QueryParamsLink } from 'components/common/QueryParamsLink';
import { UnreadLabel } from 'components/common/UnreadLabel/UnreadLabel';

import { styles } from './style';

interface ResourcesSectionListItemProps {
  label: string | number;
  id?: string;
  selected: boolean;
  onClick: (value: any, resource?: Resource) => void;
  icon?: ReactNode;
  expandIcon?: boolean;
  resource?: Resource;
}

export const ResourcesSectionListItem: FC<ResourcesSectionListItemProps> =
  React.memo(({ label, id, selected, onClick, icon, expandIcon, resource }) => {
    const unreadMessagesByResourceId = useAppSelector(
      getUnreadMessagesByResourceIdV2(id),
    );
    const currentWorkshiftId = useAppSelector(getCurrentWorkshifIdtSelector);
    const onlyCurrentWorkshift = useAppSelector(
      getOnlyCurrentWorkshiftSelector,
    );
    const isAnyUnreadMessages = useAppSelector(isAnyUnreadMessageSelector);
    const onlyWithUnread = useAppSelector(getOnlyWithUnreadSelector);
    const itemRef = useRef<null | HTMLElement>(null);
    const isItemClick = useRef(false);
    const dispatch = useAppDispatch();

    const unreadMessages = useMemo(() => {
      if (
        !unreadMessagesByResourceId?.length ||
        (!resource && !onlyCurrentWorkshift)
      ) {
        return 0;
      }

      if (onlyCurrentWorkshift) {
        const foundItem = unreadMessagesByResourceId?.find(
          (message) => message.is_current_work_shift,
        );

        return foundItem ? foundItem.count : 0;
      }

      const workshiftsIds = resource?.work_shifts?.map(({ id }) => id) || [];

      const filteredUnreadMessages = unreadMessagesByResourceId.filter(
        (unread) => workshiftsIds.includes(unread.work_shift_id),
      );

      return filteredUnreadMessages.reduce((accum, curr) => {
        return accum + curr.count;
      }, 0);
    }, [
      unreadMessagesByResourceId,
      currentWorkshiftId,
      onlyCurrentWorkshift,
      resource,
    ]);

    useEffect(() => {
      if (selected && itemRef?.current && !isItemClick.current) {
        itemRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }

      if (isItemClick.current) {
        isItemClick.current = false;
      }
    }, [selected]);

    const clickHandler = useCallback(() => {
      isItemClick.current = true;

      if (!id && isAnyUnreadMessages) {
        dispatch(updateOnlyWithUnread(!onlyWithUnread));
      }

      onClick(id, resource);
    }, [id, isAnyUnreadMessages, onlyWithUnread, dispatch, resource, onClick]);

    const itemStyles = selected
      ? ({ ...styles.item, ...styles.selected } as SxProps)
      : styles.item;

    const iconContent = icon && <Box sx={styles.icon}>{icon}</Box>;

    return (
      <ListItem sx={itemStyles} onClick={clickHandler} ref={itemRef as any}>
        <Box sx={styles.content}>
          {iconContent}
          <Typography variant="subtitle1">{label}</Typography>
        </Box>
        {(!!unreadMessages || (!id && isAnyUnreadMessages)) && (
          <QueryParamsLink sx={{ zIndex: 100 }} onClick={clickHandler}>
            <UnreadLabel qty={unreadMessages} />
          </QueryParamsLink>
        )}
        {expandIcon === true && <ExpandLess sx={styles.collapseIcon} />}
        {expandIcon === false && <ExpandMore sx={styles.collapseIcon} />}
      </ListItem>
    );
  });
