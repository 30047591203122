import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    mt: '16px',
  },

  packagesAmount: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    mb: '8px',
  },

  list: { p: 0 },
};
