import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'constants/common';
import { removeJustSelectedOrderId } from 'store/reducers/orderSlice';
import { removeJustSelectedResourceId } from 'store/reducers/resourceSlice';
import { removeJustCurrentCommonDate } from 'store/reducers/settingsSlice';
import { removeJustSelectedWorkshiftId } from 'store/reducers/workshiftSlice';

interface RemoveSelectedValuesOptions {
  resource?: boolean;
  order?: boolean;
  workshift?: boolean;
  commonDate?: boolean;
}
export const useRemoveSelectedValues = (
  options: RemoveSelectedValuesOptions = {},
) => {
  const {
    resource = true,
    order = true,
    workshift = true,
    commonDate = true,
  } = options;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  return () => {
    navigate(APP_ROUTES.root);
    resource && dispatch(removeJustSelectedResourceId());
    order && dispatch(removeJustSelectedOrderId());
    workshift && dispatch(removeJustSelectedWorkshiftId());
    commonDate && dispatch(removeJustCurrentCommonDate());
  };
};
