import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  item: {
    width: '100%',
    p: 0,
    pt: '16px',
    cursor: 'pointer',
  },
  editButton: {
    position: 'absolute',
    right: 0,
    top: '15px',
  },
  unread: {
    cursor: 'pointer',
    zIndex: 100,
  },
};
