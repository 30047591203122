import { FC } from 'react';
import ClockIcon from '@mui/icons-material/AccessTime';
import CarIcon from '@mui/icons-material/TimeToLeaveOutlined';
import { Divider, List, Typography } from '@mui/material';
import { format } from 'date-fns';
import { t } from 'ttag';
import { Order } from 'types/orders';

import { InnerSectionListItem } from 'components/common/InnerSectionListItem';
import { InnerSectionPaper } from 'components/common/InnerSectionPaper';
import { InnerSection } from 'components/ui/InnerSection';

import { DetailsSectionInnerOrdersItem } from '../DetailsSectionInnerOrdersItem';

import { styles } from './styles';

interface DetailsSectionInnerTerminalDataProps {
  orders: Order[];
  beAtTerminalTime: Date | null;
  leaveTerminalTime: Date | null;
}

export const DetailsSectionInnerTerminalData: FC<
  DetailsSectionInnerTerminalDataProps
> = ({ orders, beAtTerminalTime, leaveTerminalTime }) => {
  const ordersListContent = orders.map((order) => (
    <DetailsSectionInnerOrdersItem
      key={order.id}
      order={order}
      isTerminalReturn={!!order.dropTerminalId}
    />
  ));
  const beAtTerminalTimeItem = beAtTerminalTime && (
    <InnerSectionListItem
      icon={<ClockIcon />}
      iconColor="custom.green"
      label={t`Be at terminal latest`}
      value={format(beAtTerminalTime, 'HH:mm - dd/MM/yyyy')}
    />
  );

  const leaveTerminalTimeItem = leaveTerminalTime && (
    <InnerSectionListItem
      icon={<CarIcon />}
      iconColor="custom.blue"
      label={t`Leave terminal latest`}
      value={format(leaveTerminalTime, 'HH:mm - dd/MM/yyyy')}
    />
  );

  return (
    <InnerSection label={t`Details`} variant="empty" sx={styles.container}>
      <InnerSectionPaper>
        <Typography sx={styles.packagesAmount} variant="subtitle2">
          {t`${orders.length} errands`}
        </Typography>
        <Divider />
        {beAtTerminalTimeItem}
        {leaveTerminalTimeItem}
      </InnerSectionPaper>
      <List sx={styles.list}>{ordersListContent}</List>
    </InnerSection>
  );
};
