import { createSelector } from '@reduxjs/toolkit';
import { compareAsc, format } from 'date-fns';
import {
  getResourcesListSelector,
  getSelectedResourceIdSelector,
} from 'store/selectors/resource';
import { getCommonDateSelector } from 'store/selectors/settings';
import { createTypedSelector } from 'store/utils';
import { isCurrentWorkshift } from 'utils/workshiftUtil';

export const getSelectedWorkshiftIdSelector = createTypedSelector(
  (state) => state.workshift.selectedWorkshiftId,
);

export const getSelectedWorkshiftDateSelector = createTypedSelector(
  (state) => state.workshift.selectedWorkshiftDate,
);

export const getIsCurrentWorkshiftSelector = createSelector(
  [
    getSelectedWorkshiftIdSelector,
    getResourcesListSelector,
    getSelectedResourceIdSelector,
  ],
  (selectedWorkshiftId, resources, selectedResourceId) => {
    let currentResource = null;

    if (selectedResourceId) {
      if (!selectedWorkshiftId) {
        return true;
      }

      currentResource = resources.find(({ id }) => id === selectedResourceId);
    }

    if (currentResource) {
      const currentWorkshift = currentResource.work_shifts.find(
        ({ id, start, end }) =>
          isCurrentWorkshift(start, end) && id === selectedWorkshiftId,
      );

      return !!currentWorkshift;
    }

    return false;
  },
);

export const getIsCurrentOrFutureWorkshiftSelector = createSelector(
  [
    getSelectedWorkshiftIdSelector,
    getResourcesListSelector,
    getSelectedResourceIdSelector,
    getCommonDateSelector,
  ],
  (selectedWorkshiftId, resources, selectedResourceId, commonDate) => {
    let currentResource = null;

    if (selectedResourceId) {
      if (!selectedWorkshiftId) {
        return true;
      }

      currentResource = resources.find(({ id }) => id === selectedResourceId);
    }

    if (currentResource) {
      const currentWorkshiftIndex = currentResource.work_shifts.findIndex(
        ({ start, end }) => isCurrentWorkshift(start, end),
      );

      if (!commonDate && currentWorkshiftIndex === -1) {
        return false;
      }

      if (
        currentWorkshiftIndex === -1 &&
        compareAsc(
          new Date(format(new Date(), 'yyyy-MM-dd')),
          // @ts-ignore
          new Date(commonDate),
        ) >= 0
      ) {
        return false;
      }

      if (
        currentWorkshiftIndex === -1 &&
        compareAsc(
          new Date(format(new Date(), 'yyyy-MM-dd')),
          // @ts-ignore
          new Date(commonDate),
        ) === -1
      ) {
        return true;
      }

      const foundWorkshiftIndex = currentResource.work_shifts.findIndex(
        ({ id }) => id === selectedWorkshiftId,
      );
      return foundWorkshiftIndex >= currentWorkshiftIndex;
    }

    return false;
  },
);

export const getCurrentWorkshifIdtSelector = createSelector(
  [
    getSelectedWorkshiftIdSelector,
    getResourcesListSelector,
    getSelectedResourceIdSelector,
  ],
  (selectedWorkshiftId, resources, selectedResourceId) => {
    let currentResource = null;

    if (selectedResourceId) {
      if (selectedWorkshiftId) {
        return selectedWorkshiftId;
      }

      currentResource = resources.find(({ id }) => id === selectedResourceId);

      if (currentResource) {
        const currentWorkshift = currentResource.work_shifts.find(
          ({ start, end }) => isCurrentWorkshift(start, end),
        );

        return currentWorkshift?.id;
      }
    }
  },
);
