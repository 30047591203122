import { useMemo } from 'react';
import { useDriverHistoricalPathQuery } from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getSelectedResourceIdSelector } from 'store/selectors/resource';
import { getCurrentWorkshifIdtSelector } from 'store/selectors/workshift';

export const useMapDriverHistoricalPathData = () => {
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const selectedWorkshiftId = useAppSelector(getCurrentWorkshifIdtSelector);

  const { data } = useDriverHistoricalPathQuery(
    {
      resource_id: selectedResourceId as string,
      work_shift_id: selectedWorkshiftId as string,
    },
    {
      skip: !selectedResourceId || !selectedWorkshiftId,
    },
  );

  const historicalPathPoints = useMemo(() => {
    if (!data || !data?.length) {
      return [];
    }

    return data.map((item) => ({
      pointInfo: item,
      coords: [item.longitude, item.latitude],
    }));
  }, [data]);

  return {
    historicalPathPoints,
  };
};
